<template>
    <div class="qs-departure-airport-section">
        <div ref="beforeList" style="display: none">
            <div
                class="qs-departure-airport-section__detail__head qs-departure-airport-section__multiselect__head">
                <span class="qs-text qs-text__title--base">
                    {{$t(currentIbe.translation_key + ".departure_airports.label")}}
                </span>
                <span class="qs-text qs-text__bold">
                    {{ summaryText }}
                </span>
            </div>
            <hr />
        </div>
        <div
            ref="afterList"
            class="qs-departure-airport-section__multiselect__footer"
            style="display: none">
            <span @click="removeAll">
                {{$t(currentIbe.translation_key + ".departure_airports.action.remove")}}
            </span>
            <QsButton
                @action="submit"
                class="qs-departure-airport-section__detail__body__button"
                :label="$t(currentIbe.translation_key + '.departure_airports.action.apply')">
            </QsButton>
        </div>
        <multiselect
            ref="airportselect"
            class="qs-departure-airport-section__multiselect"
            group-values="airports"
            track-by="3LC"
            v-model="selected"
            group-label="category"
            :close-on-select="false"
            :clear-on-select="false"
            placeholder=""
            label="label"
            :multiple="true"
            @close="showLabel"
            :options="currentIbe.departure_airports.departure_airport_list">
            <template slot="selection">
                <div></div>
            </template>
            <template slot="noResult">
                <span class="qs-text">{{
                    $t("global.no-elements-found")
                }}</span>
            </template>
            <template slot="option" slot-scope="props">
                <div class="qs-departure-airport-section__multiselect__option">
                    <div
                        v-if="props.option.$isLabel"
                        class="qs-text qs-text__bold qs-text__upper qs-text-large">
                        <span
                            class="qs-departure-airport-section__multiselect__option__checkbox"
                            :class="{checked: selectedGroups.find((o) =>o['category'] ===props.option.$groupLabel),}">
                        </span>

                        <span>{{ props.option.$groupLabel }}</span>
                    </div>
                    <div v-else>
                        <span
                            class="qs-departure-airport-section__multiselect__option__checkbox"
                            :class="{checked: selected ? selected.find((o) => o['3LC'] === props.option['3LC']) : false,}">
                        </span>
                        <span class="qs-text">{{ props.option.label }}</span>
                        <span
                            v-if="shouldDisplayCountryCodeFor(props.option['3LC'])"
                            class="qs-text qs-text--color-secondary--dark">
                            ({{ props.option["country_code"] }})
                        </span>
                        <span class="qs-text qs-text--color-secondary--dark">
                            {{ props.option["3LC"] }}
                        </span>
                    </div>
                </div>
            </template>
        </multiselect>
    </div>
</template>

<script>
import QsButton from '../QsButton/QsButton'
import currentSelectionMixin from '../../mixins/currentSelectionMixin'
import ibeDataMixin from '../../mixins/ibeDataMixin'
import Multiselect from 'vue-multiselect'

const lodashDifference = require('lodash.difference')

export default {
    name: 'QsDepartureAirportSection',
    components: { Multiselect, QsButton },
    mixins: [currentSelectionMixin, ibeDataMixin],
    data () {
        return {
            selected: [],
            selectedGroups: []
        }
    },
    mounted () {
        this.selected = this.departureAirportSelection
        this.showLabel()
        let listRef = this.$refs.airportselect.$refs.list
        listRef.insertBefore(this.$refs.beforeList, listRef.firstChild)
        listRef.appendChild(this.$refs.afterList)
        this.$refs.beforeList.style.display = 'block'
        this.$refs.afterList.style.display = 'block'
    },
    computed: {
        summaryText () {
            return this.selected ? this.selected.reduce((value, curr) => value + ' ' + curr['3LC'], '') : ''
        }
    },
    watch: {
        currentIbe () {
            this.options = []
            this.selected = null
            this.search = ''
            this.setDestinationSearchQuery('')
            this.showLabel()
        },
        selected: {
            deep: true,
            handler () {
                this.selectedGroups = this.currentIbe.departure_airports.departure_airport_list.filter(
                    (group) => {
                        return !lodashDifference(
                            group.airports.map(a => a['3LC']),
                            this.selected.map(a => a['3LC'])
                        ).length
                    }
                )
                this.setDepartureAirportSelection(this.selected)
            }
        }
    },
    methods: {
        showLabel () {
            this.$nextTick(() => {
                if (this.selected.length === 1) {
                    this.$refs.airportselect.$refs.search.value = this.$t(this.selected.reduce((value, curr) => value + ' ' + curr['label'], ''))
                } else if (this.selected.length === 0) {
                    this.$refs.airportselect.$refs.search.value = this.$t(this.selected.length) + 'Abflughafen'
                } else {
                    this.$refs.airportselect.$refs.search.value = this.$t(this.selected.length) + ' Abflughäfen'
                }
            })
        },
        submit () {
            this.$refs.airportselect.$refs.search.blur()
        },
        removeAll () {
            this.selected = []
        },
        shouldDisplayCountryCodeFor (dlc) {
            const groupWithDlc = this.currentIbe.departure_airports.departure_airport_list.find(
                ({ airports }) => airports.map(d => d['3LC']).includes(dlc)
            )
            return groupWithDlc.display_country_code
        }
    }
}
</script>

<style lang="scss">
@import "QsDepartureAirportSection";
</style>
