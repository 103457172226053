<template>
  <div class="qs-quick-search" v-if="ibes">
    <div style="display: none">
      <svg>
        <symbol version="1.1" id="arrow-down" focusable="false" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 448 321" style="enable-background:new 0 0 448 321;" xml:space="preserve">
              <path d="M207,284.6L12.7,90.2c-9.4-9.4-9.4-24.6,0-33.9l22.7-22.7c9.4-9.4,24.5-9.4,33.9,0l154.7,154l154.7-154
              c9.4-9.3,24.5-9.3,33.9,0l22.7,22.7c9.4,9.4,9.4,24.6,0,33.9L241,284.6C231.6,294,216.4,294,207,284.6L207,284.6z"/>
          </symbol>
        <symbol version="1.1" id="double-arrow-right" focusable="false" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 448 376" style="enable-background:new 0 0 448 376;" xml:space="preserve">
            <path d="M224.3,206.1l-136,136c-9.4,9.4-24.6,9.4-33.9,0l-22.6-22.6c-9.4-9.4-9.4-24.6,0-33.9l96.4-96.4L31.8,92.8
                c-9.4-9.4-9.4-24.6,0-33.9l22.5-22.8c9.4-9.4,24.6-9.4,33.9,0l136,136C233.7,181.5,233.7,196.7,224.3,206.1z M416.3,172.1l-136-136
                c-9.4-9.4-24.6-9.4-33.9,0l-22.6,22.6c-9.4,9.4-9.4,24.6,0,33.9l96.4,96.4l-96.4,96.4c-9.4,9.4-9.4,24.6,0,33.9l22.6,22.6
                c9.4,9.4,24.6,9.4,33.9,0l136-136C425.7,196.7,425.7,181.5,416.3,172.1L416.3,172.1z"/>
          </symbol>
      </svg>
    </div>
    <QsHeader/>
    <QsContent/>
  </div>
</template>

<script>
  import QsHeader from '../components/QsHeader/QsHeader'
  import QsContent from '../components/QsContent/QsContent'
  import store from '../store'
  import ibeDataMixin from '../mixins/ibeDataMixin'

  export default {
    name: 'quick-search',
    mixins: [ibeDataMixin],
    props: ['colors', 'config'],
    components: {
      QsContent,
      QsHeader
    },
    mounted () {
      try {
        if (this.colors) {
          let colors = JSON.parse(this.colors)
          Object.keys(colors).forEach((key) => {
            document.documentElement.style.setProperty('--color-' + key, colors[key])
            document.documentElement.style.setProperty('--color-' + key + '-rgb', HEX2RGB(colors[key]).toString())
          })
        }
      } catch (e) {
      }

      let config = {}
      try {
        if (this.config) {
          config = JSON.parse(this.config)
        }
      } catch (e) {
      }
      store.dispatch('ibeData/setIbeData', config)
    }
  }

  function HEX2RGB (hex) {
    if (hex.charAt(0) === '#') {
      hex = hex.substr(1)
    }
    if (hex.length !== 6) {
      return [0, 0, 0]
    }
    let values = hex.split('')

    return [
      parseInt(values[0].toString() + values[1].toString(), 16),
      parseInt(values[2].toString() + values[3].toString(), 16),
      parseInt(values[4].toString() + values[5].toString(), 16)
    ]
  }
</script>

<style scoped lang="scss">
</style>
