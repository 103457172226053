<template>
  <div class="qs-travel-time-section" v-click-outside="hideDetail">
    <div class="qs-travel-time-section__minimized">
      <div @click="toggleCalendarVisibility" class="qs-travel-time-section__minimized__dates">
        <QsInput :label="$t(currentIbe.translation_key + '.dates.label')"
                 :value="calendarSummaryText"
        />
      </div>
      <div v-show="hasTravelDuration">
        <div @click="toggletravelDurationVisibility" class="qs-travel-time-section__minimized__duration">
          <QsInput :label="$t(currentIbe.translation_key + '.dates.duration.label')"
                  :value="travelDurationSummaryText"
                  slim
          />
        </div>
      </div>
    </div>
    <div v-show="calendarDetailVisible"
         class="qs-travel-time-section__detail qs-travel-time-section__detail--spacing ">
      <div class="qs-travel-time-section__detail__head">
        <div class="qs-travel-time-section__detail__summary">
          <div :class="{'qs-travel-time-section__detail__summary__active': range.start !== range.end}">
            <p class="qs-travel-time-section__detail__summary__label">
              {{$t(currentIbe.translation_key + '.dates.departure_date.label')}}
            </p>
            <p class="qs-travel-time-section__detail__summary__value">
              {{range.start ? range.start.toLocaleDateString() : '-'}}
            </p>
          </div>
          <div :class="{'qs-travel-time-section__detail__summary__active': range.start === range.end}">
            <p class="qs-travel-time-section__detail__summary__label">
              {{$t(currentIbe.translation_key + '.dates.return_date.label')}}
            </p>
            <p class="qs-travel-time-section__detail__summary__value">
              {{range.end ? range.end.toLocaleDateString() : '-'}}
            </p>
          </div>
        </div>
      </div>
      <hr class="qs-travel-time-section__detail__hr">
      <div class="qs-travel-time-section__detail__body">
        <v-date-picker
          is-expanded
          is-inline
          mode='range'
          v-model="range"
          :min-date="currentMin"
          :max-date="currentMax"
          @dayclick="dayClick"
          :theme="calendarTheme"
        />
        <QsButton
          @action="toggleCalendarVisibility"
          class="qs-travel-time-section__detail__body__button"
          :label="$t(currentIbe.translation_key + '.dates.action.CTA')"
          full-width>
        </QsButton>
      </div>
    </div>
    <div v-show="travelDurationDetailVisible" class="qs-travel-time-section__detail">
      <div class="qs-travel-time-section__detail__body">
        <template v-for="(optionSet,key) in travelDurations">
          <div v-if="typeof optionSet === 'object'" :key="key" @click="valueChanged">
            <hr class="qs-travel-time-section__detail__hr--light">
            <div v-for="option in optionSet" :key="key + '/' + option">
              <label class="qs-travel-time-section__detail__option"
                     :class="[
                     {'qs-travel-time-section__detail__option__disabled':!availableTravelDurations.includes('count/' + key + '/' + option)},
                     {'active':selectedTravelDuration==='count/' + key + '/' + option}
                     ]">
                <input type="radio"
                       :disabled="!availableTravelDurations.includes('count/' + key + '/' + option)"
                       v-model="selectedTravelDuration"
                       :value="'count/' + key + '/' + option"
                       name="duration"
                />
                <span class="qs-text">{{translateTravelDuration('count', key, option)}}</span>
              </label>
            </div>
          </div>
          <div v-else :key="key" @click="valueChanged">
            <label class="qs-travel-time-section__detail__option"
                   :class="{'active':selectedTravelDuration==='duration/' + key}">
              <input type="radio"
                     v-model="selectedTravelDuration"
                     :value="'duration/' + key"
                     name="duration"
              />
              <span class="qs-text"> {{$t(currentIbe.translation_key + '.dates.duration.' + key)}}</span>
            </label>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import QsButton from '../QsButton/QsButton'
  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import QsInput from '../QsInput/QsInput'
  import ibeDataMixin from '../../mixins/ibeDataMixin'
  import ClickOutside from 'vue-click-outside'
  import day from 'dayjs'
  import theme from '../../datePickerTheme.json'

  const lodashPickBy = require('lodash.pickby')

  export default {
    name: 'QsTravelTimeSection',
    components: { QsInput, QsButton },
    mixins: [currentSelectionMixin, ibeDataMixin],
    props: {
      hasTravelDuration: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        range: {
          start: null,
          end: null
        },
        calendarDetailVisible: false,
        travelDurationDetailVisible: false,
        selectedTravelDuration: 'count/weeks/1',
        calendarTheme: theme
      }
    },
    created () {
      this.range.start = this.dateRules.default_departure_date
      this.range.end = this.dateRules.default_return_date
      let tmp = this.travelDurationSelection.unit ? this.travelDurationSelection : this.currentIbe.date_options.duration.selected_default_duration
      let prefix = (tmp.unit === 'any' || tmp.unit === 'exact' ? 'duration' : 'count')
      this.selectedTravelDuration = prefix + '/' + tmp.unit + (tmp.value ? '/' + tmp.value : '')
    },
    computed: {
      availableTravelDurations () {
        const durations = lodashPickBy(this.travelDurations, Array.isArray)
        return [...Object.keys(durations)
          .reduce((result, key) => ([...result, ...durations[key]
            .filter(option => {
              switch (key) {
                case 'days':
                  return option <= this.calculatedDuration
                case 'weeks':
                  return option * 7 <= this.calculatedDuration
                case 'time_spans':
                  const [, to] = option.split('-').map(Number)
                  return to <= this.calculatedDuration
              }
            })
            .map(option => 'count/' + key + '/' + option)
          ]), []), ...['duration/any', 'duration/exact']]
      },
      travelDurations () {
        return this.currentIbe.date_options.duration.duration_options
      },
      dateRules () {
        return this.currentIbe.date_options.departure_return
      },
      calendarSummaryText () {
        return (this.range.start ? this.range.start.toLocaleDateString() : this.dateRules.default_departure_date.toLocaleDateString()) +
          ' - ' +
          (this.range.end ? this.range.end.toLocaleDateString() : this.dateRules.default_return_date.toLocaleDateString())
      },
      travelDurationSummaryText () {
        const [type, key, value] = (this.selectedTravelDuration || '').split('/')
        return this.translateTravelDuration(type, key, value)
      },
      currentMin () {
        if (!this.range.end && this.range.start) {
          return new Date(this.range.start.getTime() + (this.dateRules.min_selectable_time_span - new Date()))
        } else {
          return this.dateRules.min_selectable_departure_date
        }
      },
      currentMax () {
        if (!this.range.end && this.range.start) {
          return new Date(this.range.start.getTime() + Math.min(this.dateRules.max_selectable_time_span - new Date(), this.dateRules.max_selectable_return_date.getTime()))
        } else {
          return this.dateRules.max_selectable_departure_date
        }
      },
      calculatedDuration () {
        return day(this.range.end).diff(day(this.range.start), 'days')
      },
      lastDayValue () {
        return this.travelDurations.days[this.travelDurations.days.length - 1]
      }
    },
    watch: {
      range: {
        deep: true,
        handler () {
          this.setTravelTimeSelection(this.range)
        }
      },
      availableTravelDurations: {
        deep: true,
        handler () {
          if (!this.availableTravelDurations.includes(this.selectedTravelDuration) && !isNaN(this.calculatedDuration)) {
            this.selectedTravelDuration = 'count/days/' + (this.calculatedDuration > this.lastDayValue ? this.lastDayValue : this.calculatedDuration)
          }
        }
      },
      selectedTravelDuration () {
        const [, unit, value] = (this.selectedTravelDuration || '').split('/')
        this.setTravelDurationState({ unit, value })
      }
    },
    methods: {
      valueChanged () {
          this.travelDurationDetailVisible = false
      },
      translateTravelDuration (type, key, value) {
        if (key === 'days') {
          if (parseInt(value) === this.lastDayValue) {
            return this.$t(this.currentIbe.translation_key + '.dates.count.days_plus')
          }
        }

        return this.$tc(this.currentIbe.translation_key + '.dates.' + type + '.' + key, value, parseInt(value))
      },
      toggleCalendarVisibility () {
        this.calendarDetailVisible = !this.calendarDetailVisible
        if (this.calendarDetailVisible) {
          this.travelDurationDetailVisible = false
        }
      },
      toggletravelDurationVisibility () {
        this.travelDurationDetailVisible = !this.travelDurationDetailVisible
        if (this.travelDurationDetailVisible) {
          this.calendarDetailVisible = false
        }
      },
      hideDetail () {
        if (this.calendarDetailVisible) {
          if (this.range.end === null) {
            this.range.end = day(this.range.start).add(this.currentIbe.date_options.duration.default_duration, 'days').toDate()
          }
          this.calendarDetailVisible = false
        }
        if (this.travelDurationDetailVisible) {
          this.travelDurationDetailVisible = false
        }
      },
      dayClick (event) {
        this.range.start = event.date
        if (event.isDisabled) {
          return
        }
        if (event.date.getTime() === this.range.start.getTime()) {
          this.$nextTick(() => {
            this.range.end = event.date
          })
          return
        }
        if (!this.range.start) {
          this.range.start = event.date
        } else if (this.range.start && !this.range.end) {
          this.range.end = event.date
        } else {
          this.range.end = null
          this.range.start = event.date
        }
      }
    },
    directives: {
      ClickOutside
    }
  }
</script>

<style lang="scss">
  @import "QsTravelTimeSection";
</style>
