import { mapActions, mapGetters } from 'vuex'
import day from 'dayjs'
import queryString from 'query-string'

export default {
  computed: {
    ...mapGetters({
      ibes: 'ibeData/ibes',
      currentIbe: 'ibeData/currentIbe',
      travellerSelection: 'currentSelection/travellerSelection',
      departureAirportSelection: 'currentSelection/departureAirportSelection',
      departureUserInput: 'currentSelection/departureUserInput',
      travelTimeSelection: 'currentSelection/travelTimeSelection',
      travelDurationSelection: 'currentSelection/travelDurationSelection',
      destinationSelection: 'currentSelection/destinationSelection',
      destinationSelectionValidationError: 'currentSelection/destinationSelectionValidationError',
      destinationUserInput: 'currentSelection/destinationUserInput',
      shipSelection: 'currentSelection/shipSelection',
      cruiseTypeSelection: 'currentSelection/cruiseTypeSelection',
      rentalCarDeparture: 'currentSelection/rentalCarDeparture',
      rentalCarReturn: 'currentSelection/rentalCarReturn',
      destinationSearchQuery: 'currentSelection/destinationSearchQuery',
      shipSearchQuery: 'currentSelection/shipSearchQuery'
    })
  },
  methods: {
    ...mapActions({
      setCurrentIbe: 'ibeData/setCurrentIbe',
      setTravellerSelection: 'currentSelection/setTravellerState',
      setDepartureAirportSelection: 'currentSelection/setDepartureAirportState',
      setDepartureUserInput: 'currentSelection/setDepartureUserInput',
      setTravelTimeSelection: 'currentSelection/setTravelTimeState',
      setTravelDurationState: 'currentSelection/setTravelDurationState',
      setDestinationState: 'currentSelection/setDestinationState',
      setDestinationValidationError: 'currentSelection/setDestinationValidationError',
      setDestinationUserInput: 'currentSelection/setDestinationUserInput',
      setShipState: 'currentSelection/setShipState',
      setCruiseTypeSelection: 'currentSelection/setCruiseTypeSelection',
      setRentalCarReturn: 'currentSelection/setRentalCarReturn',
      setRentalCarDeparture: 'currentSelection/setRentalCarDeparture',
      setDestinationSearchQuery: 'currentSelection/setDestinationSearchQuery',
      setShipSearchQuery: 'currentSelection/setShipSearchQuery'
    }),
    validate () {
      if (this.currentIbe.provider === 'sunnycars') {
        if (this.destinationSelection?.id) {
          return true
        } else {
          this.setDestinationValidationError(true)
            return false
        }
      }
      return true
    },
    getSearchUrl (advanced = false) {
      if (this.currentIbe.provider === 'tt') {
        let path = ''
        let dur = this.computeDays(this.travelDurationSelection)
        if (dur && typeof dur === 'string') {
          dur = dur.replace('-', ',')
        }
        const parameters = {
          ...(this.currentIbe.ibeOptions || {}),
          depap: this.departureAirportSelection ? this.departureAirportSelection.map(curr => curr['3LC']).join(',') : null,
          adult: this.travellerSelection.adults,
          child: this.travellerSelection.children.map(child => child.age).join(',') || null,
          ddate: day(this.travelTimeSelection.start).format('YYYY-MM-DD'),
          rdate: day(this.travelTimeSelection.end).format('YYYY-MM-DD'),
          dur
        }
        switch (this.destinationSelection?.category) {
          case 'hotel':
            path = 'offer'
            parameters.aid = this.destinationSelection.id
            break
          case 'city':
            path = 'hotel'
            parameters.cyid = this.destinationSelection.id
            parameters.rid = this.destinationSelection.id
            break
          case 'region':
            path = 'hotel'
            parameters.rid = this.destinationSelection.id
            break
          case 'flight':
            path = 'offer'
            parameters.destap = this.destinationSelection.id
            break
          case 'country':
            path = 'region'
            parameters.rgid = this.destinationSelection.id
            break
          default:
            path = 'region'
            parameters.rgid = ['']
            break
        }
        if (this.currentIbe.selected_url === 'iframe_url') {
          return (this.setUrl() || '') + btoa(this.currentIbe.url + '/' + (advanced ? 'search' : path) + '?' + queryString.stringify(parameters, { skipNull: true }))
        } else {
          return (this.setUrl() || '') + '/' + (advanced ? 'search' : path) + '?' + queryString.stringify(parameters, { skipNull: true })
        }
      } else if (this.currentIbe.provider === 'profewo') {
        const parameters = {
          ...(this.currentIbe.ibeOptions || {}),
          suchisoland: this.destinationSelection.id_2,
          namesort: this.destinationSelection.id,
          pers: this.travellerSelection.adults,
          isoanrdatum: day(this.travelTimeSelection.start).format('YYYY-MM-DD'),
          isoabrdatum: day(this.travelTimeSelection.end).format('YYYY-MM-DD')
        }
        switch (this.destinationSelection?.category) {
          case 'country':
            parameters.searchmode = ['COUNTRY']
            parameters.suchHTML = this.destinationSelection.id_2
            break
            default:
              parameters.searchmode = ['LOC']
              parameters.suchHTML = (this.destinationSelection.name) + ' ,' + (this.destinationSelection.id_2)
            break
        }
        if (this.currentIbe.selected_url === 'iframe_url') {
          return (this.setUrl() || '') + btoa(this.currentIbe.url + '/?' + queryString.stringify(parameters, { skipNull: true }))
        } else {
          return (this.setUrl() || '') + '/' + '?' + queryString.stringify(parameters, { skipNull: true })
        }
      } else if (this.currentIbe.provider === 'ypsilon') {
        const parameters = {
          ...(this.currentIbe.ibeOptions || {}),
          depapt1: this.departureUserInput,
          dstapt1: this.destinationUserInput,
          pax: this.travellerSelection.adults,
          pax_chd: this.travellerSelection.children.filter(({ age }) => age >= 2).length || null,
          pax_inf: this.travellerSelection.children.filter(({ age }) => age < 2).length || null,
          depdate1: day(this.travelTimeSelection.start).format('YYYY-MM-DD'),
          retdate1: day(this.travelTimeSelection.end).format('YYYY-MM-DD'),
          st: 'p'
        }
        if (this.currentIbe.selected_url === 'iframe_url') {
          return (this.setUrl() || '') + btoa(this.currentIbe.url + '/?' + queryString.stringify(parameters, { skipNull: true }))
        } else {
          return (this.setUrl() || '') + '/' + '?' + queryString.stringify(parameters, { skipNull: true })
        }
      } else if (this.currentIbe.provider === 'tuicars') {
        let pickUpStation = null
        pickUpStation = this.destinationSelection ? this.destinationSelection.id : null
        const parameters = {
          ...(this.currentIbe.ibeOptions || {}),
          pickUpStation,
          pickUpDate: day(this.rentalCarDeparture).format('DD.MM.YYYY'),
          dropOffDate: day(this.rentalCarReturn).format('DD.MM.YYYY'),
          pickUpTime: day(this.rentalCarDeparture).format('HH:mm'),
          dropOffTime: day(this.rentalCarReturn).format('HH:mm')
        }
        if (this.currentIbe.selected_url === 'iframe_url') {
          return (this.setUrl() || '') + btoa(this.currentIbe.url + '/?' + queryString.stringify(parameters, { skipNull: true }))
        } else {
          return (this.setUrl() || '') + '/' + '?' + queryString.stringify(parameters, { skipNull: true })
        }
      } else if (this.currentIbe.provider === 'sunnycars') {
        const parameters = {
          ...(this.currentIbe.ibeOptions || {}),
          prid: this.destinationSelection.id,
          pd: day(this.rentalCarDeparture).format('DDMMYYYY'),
          pt: day(this.rentalCarDeparture).format('HHmm'),
          dd: day(this.rentalCarReturn).format('DDMMYYYY'),
          dt: day(this.rentalCarReturn).format('HHmm')
        }
        if (this.currentIbe.selected_url === 'iframe_url') {
          return (this.setUrl() || '') + btoa(this.currentIbe.url + '/?' + queryString.stringify(parameters, { skipNull: true }))
        } else {
          return (this.setUrl() || '') + '/' + '?' + queryString.stringify(parameters, { skipNull: true })
        }
      } else if (this.currentIbe.provider === 'ehoi') {
        let destination = null
        let destination2 = null
        let reederei = null
        let reederei2 = null
        if (this.cruiseTypeSelection === 'sea') {
          destination = this.destinationSelection ? this.destinationSelection.id : null
          reederei = this.shipSelection ? this.shipSelection.id : null
        }
        if (this.cruiseTypeSelection === 'river') {
          destination2 = this.destinationSelection ? this.destinationSelection.id : null
          reederei2 = this.shipSelection ? this.shipSelection.id : null
        }
        const parameters = {
          ...(this.currentIbe.ibeOptions || {}),
          cruisingareaTyp: this.cruiseTypeSelection === 'sea' ? 1 : 2,
          searchstring: (this.cruiseTypeSelection) + queryString.stringify(this.shipSelection),
          destination,
          destination2,
          reederei,
          reederei2,
          datum_von: day(this.travelTimeSelection.start).format('DD.MM.YYYY'),
          datum_bis: day(this.travelTimeSelection.end).format('DD.MM.YYYY'),
          fuseaction: 'search.main'
        }
        if (this.currentIbe.selected_url === 'iframe_url') {
          return (this.setUrl() || '') + btoa(this.currentIbe.url + '/?' + queryString.stringify(parameters, { skipNull: true }))
        } else {
          return (this.setUrl() || '') + '/' + '?' + queryString.stringify(parameters, { skipNull: true })
        }
      } else if (this.currentIbe.provider === 'cruisecompass') {
        let region = null
        let red = null
        if (this.cruiseTypeSelection === 'sea' || this.cruiseTypeSelection === 'river') {
          region = this.destinationSelection ? this.destinationSelection.id : ''
          red = this.shipSelection ? this.shipSelection.id : ''
        }
        const parameters = {
          ...(this.currentIbe.ibeOptions || {}),
          t: this.cruiseTypeSelection === 'sea' ? 'K' : 'R',
          region,
          red,
          sdt: day(this.travelTimeSelection.start).format('DD.MM.YYYY'),
          bdt: day(this.travelTimeSelection.end).format('DD.MM.YYYY'),
          dur: this.computeDays(this.travelDurationSelection)
        }
        if (this.currentIbe.selected_url === 'iframe_url') {
          return (this.setUrl() || '') + btoa(this.currentIbe.url + '/?' + queryString.stringify(parameters, { skipNull: true }))
        } else {
          return (this.setUrl() || '') + '/' + '?' + queryString.stringify(parameters, { skipNull: true })
        }
      }
    },
    computeDays (travelDurationSelection) {
      if (travelDurationSelection.unit === 'weeks') {
        var oldValue = travelDurationSelection.value
        var newValue = (oldValue * 7)
        return newValue
      }
      if (travelDurationSelection.unit === 'exact') {
        return 'exact'
      }
      return travelDurationSelection.value
    },
    setUrl (url) {
      //  the value wich kind of url to select
      this.url = this.currentIbe.url
      //  the value of url wich takes you to iframe
      this.iframe_url = this.currentIbe.iframe_url
      //  the value of url aka base_url
      const { selected_url } = this.currentIbe
      if (selected_url === 'iframe_url') {
        return this.iframe_url
      } else {
        return this.url
      }
    },
    submitSearch () {
      if (this.validate()) {
        this.redirect(this.getSearchUrl())
      }
      console.log(this.getSearchUrl())
    },
    submitAdvancedSearch () {
      this.redirect(this.getSearchUrl(true))
    },
    redirect (url) {
      if (this.currentIbe.newTab) {
        window.open(
          url,
          '_blank'
        )
      } else {
        window.location = url
      }
    }
  },
  data () {
    return {
      url: '',
      iframe_url: '',
      selected_url: ''
    }
  }
}
