<template>
    <div class="qs-header">
        <div class="qs-header__menu-placeholder">{{$t(currentIbe.translation_key + '.label')}}</div>
        <div class="qs-header__menu-wrapper">
      <span class="qs-header__toggle" @click="menuVisible = !menuVisible">
        <svg class="qs-header__toggle__icon">
          <use xlink:href="#arrow-down"/>
        </svg>
      </span>
            <ul class="qs-header__menu" :class="{}">
                <li class="qs-header__menu__entry"
                    v-for="(header, key) in ibes"
                    v-bind:key="key"
                    :class="[{'qs-header__menu__entry__current': currentIbe.refId === header.refId},{'qs-header__menu__entry-visible':menuVisible}]"
                    @click="setCurrentHeader(key)"
                >
                    <span class="qs-text">{{$t(header.translation_key + '.label')}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  import currentSelectionMixin from '../../mixins/currentSelectionMixin'

  export default {
    name: 'QsHeader',
    mixins: [currentSelectionMixin],
    data () {
      return {
        menuVisible: false
      }
    },
    methods: {
      setCurrentHeader (header) {
        this.setCurrentIbe(header)
        this.menuVisible = false
        // delete selection on tab/ibe change
        this.setDestinationSearchQuery('')
        this.setDepartureAirportSelection([])
      }
    },
    created () {
      if (!this.currentIbe) {
        this.setCurrentIbe(Object.keys(this.ibes)[0] || null)
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "QsHeader";
</style>
