<template>
  <div class="qs-destination-input" :class="[{'qs-destination-input--half':half},{'qs-destination-input--full':full}]">
    <input type="text" v-model="search" :placeholder="$t(currentIbe.translation_key + translationPrefix + 'label')">
  </div>
</template>

<script>

  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import ibeDataMixin from '../../mixins/ibeDataMixin'

  export default {
    name: 'QsDestinationInput',
    mixins: [currentSelectionMixin, ibeDataMixin],
    props: {
      full: {
        type: Boolean,
        default: false
      },
      half: {
        type: Boolean,
        default: false
      },
      translationPrefix: {
        type: String,
        default: '.destination.'
      }
    },
    data () {
      return {
        search: ''
      }
    },
    watch: {
      currentIbe () {
        this.search = ''
      },
      search () {
        this.setDestinationUserInput(this.search)
      }
    }
  }
</script>

<style lang="scss">
  @import "QsDestinationInput";
</style>
