import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar'
import vueCustomElement from 'vue-custom-element'
import QuickSearch from './views/QuickSearch'
import VueI18n from 'vue-i18n'
import messages from './i18n/de_DE'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'de',
  messages
})
Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VCalendar, { firstDayOfWeek: 2 })
Vue.use(vueCustomElement)

Vue.customElement('vue-quick-search', { ...QuickSearch, store, i18n })

if (document.getElementById('app')) {
  const vue = new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
  })
  vue.$mount('#app')
}
