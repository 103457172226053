<template>
  <div class="qs-counter" :class="{'qs-error':invalid}">
    <div class="qs-counter__label qs-text">
      <span class="qs-counter__label__type">{{label}}</span>
      <span class="qs-counter__label__description">{{description}}</span>
    </div>
    <div class="qs-counter__input">
      <button class="qs-counter__input__minus"
              @click="decrease"
              :disabled="decrDisabled"
      >-
      </button>
      <span class="qs-counter__input__value qs-text">{{value}}</span>
      <button class="qs-counter__input__plus"
              @click="increase"
              :disabled="incrDisabled"
      >+
      </button>
    </div>

  </div>
</template>

<script>

  export default {
    name: 'QsCounter',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      label: {
        type: String
      },
      description: {
        type: String
      },
      value: {
        type: Number
      },
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 99
      },
      invalid: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      incrDisabled () {
        return this.value >= this.max
      },
      decrDisabled () {
        return this.value <= this.min
      }
    },
    methods: {
      decrease () {
        this.$emit('change', this.decrDisabled ? this.value : this.value - 1)
      },
      increase () {
        this.$emit('change', this.incrDisabled ? this.value : this.value + 1)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "QsCounter";
</style>
