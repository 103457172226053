<template>
  <div class="qs-content" :class="currentIbe.provider">

    <!-- Package -->
    <template v-if="currentIbe.refId === 1">
      <QsDepartureAirportSection :key="11"/>
      <QsDestinationSection :key="12" half/>
      <QsTravelTimeSection :key="13"/>
      <QsTravellerSection :key="14" @valid="valid = $event" />
    </template>

    <!-- Hotel -->
    <template v-else-if="currentIbe.refId === 2">
      <QsDestinationSection :key="21" full/>
      <QsTravelTimeSection :key="22"/>
      <QsTravellerSection :key="23" @valid="valid = $event" />
    </template>

    <!-- Rentalcar TUIcar -->
    <template v-else-if="currentIbe.refId === 3">
      <QsDestinationSection :key="31" :remove-all-allowed="false" translation-prefix=".station." :show-single-category-label="false" full/>
      <QsDateTimeSpanSection :key="32"/>
    </template>

    <!-- Rentalcar SunnyCars-->
    <template v-else-if="currentIbe.refId === 7">
      <QsDestinationSection :key="71" translation-prefix=".station." :show-single-category-label="false" full :class="{ 'qs-error': isError}"/>
      <QsDateTimeSpanSection :key="72"/>
    </template>

    <!-- Holidayhome proFewo -->
    <template v-else-if="currentIbe.refId === 4">
      <QsDestinationSection :key="41" full :class="{ 'qs-error': isError}"/>
      <QsTravelTimeSection :has-travel-duration="false" :key="42" />
      <QsTravellerSection :key="43" :max-travellers="currentIbe.travellers.max_travellers" />
    </template>

    <!-- Cruise CruiseCompass-->
    <template v-else-if="currentIbe.refId === 8">
      <QsCruiseTypeSection :key="81"/>
      <QsDestinationSection :key="82" :load-on-open="true" half :min-query-length="0" :showSingleCategoryLabel="false" />
      <QsShipSection :key="83" :load-on-open="true" :min-query-length="0" :showSingleCategoryLabel="false" />
      <QsTravelTimeSection :key="84"/>
    </template>

    <!-- Cruise ehoi-->
    <template v-else-if="currentIbe.refId === 5">
      <QsCruiseTypeSection :key="51"/>
      <QsDestinationSection :key="52" half :load-on-open="true" :min-query-length="0" :showSingleCategoryLabel="false" />
      <QsShipSection :key="53" :load-on-open="true" :min-query-length="0" :showSingleCategoryLabel="false" />
      <QsTravelTimeSection :key="54"/>
    </template>

    <!-- Flight ypsilon -->
    <template v-else-if="currentIbe.refId === 6">
      <QsDepartureInput :key="61"/>
      <QsDestinationInput :key="62" half/>
      <QsTravelTimeSection :has-travel-duration="false" :key="63"/>
      <QsTravellerSection :key="64" @valid="valid = $event" />
    </template>

    <!-- Flight -->
    <template v-else-if="currentIbe.refId === 9">
      <QsDepartureAirportSection :key="91"/>
      <QsDestinationSection :key="92" half/>
      <QsTravelTimeSection :key="93"/>
      <QsTravellerSection :key="94" @valid="valid = $event" />
    </template>

    <div v-if="valid" class="qs-content__buttons" @mouseover="checkSelected">
      <QsButton :label="$t(currentIbe.translation_key + '.action.apply')" :disabled="isDisabled" @action="submitSearch"/>
      <a v-show="this.currentIbe.advanced_searchable" @click="submitAdvancedSearch">
        Erweiterte Suche
      </a>
    </div>
    <div v-else class="qs-content__buttons">
      <QsButton :label="$t(currentIbe.translation_key + '.action.apply')" disabled />
      <a v-show="this.currentIbe.advanced_searchable" disabled>
        Erweiterte Suche
      </a>
    </div>

    <div v-if="development" style="background: white;position: fixed;bottom: 35px;padding: 10px;" id="dev-container">
      <p>current selection:</p>
      <p>{{this.currentIbe.provider}}</p>
    </div>
  </div>
</template>

<script>
  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import QsButton from '../QsButton/QsButton'
  import QsTravellerSection from '../QsTravellerSection/QsTravellerSection'
  import QsDepartureInput from '../QsDepartureInput/QsDepartureInput'
  import QsDepartureAirportSection from '../QsDepartureAirportSection/QsDepartureAirportSection'
  import QsTravelTimeSection from '../QsTravelTimeSection/QsTravelTimeSection'
  import QsDestinationSection from '../QsDestinationSection/QsDestinationSection'
  import QsDestinationInput from '../QsDestinationInput/QsDestinationInput'
  import QsCruiseTypeSection from '../QsCruiseTypeSection/QsCruiseTypeSection'
  import QsDateTimeSpanSection from '../QsDateTimeSpanSection/QsDateTimeSpanSection'
  import QsShipSection from '../QsShipSection/QsShipSection'

  export default {
    name: 'QsContent',
    components: {
      QsShipSection,
      QsDateTimeSpanSection,
      QsCruiseTypeSection,
      QsDestinationSection,
      QsDestinationInput,
      QsDepartureAirportSection,
      QsTravelTimeSection,
      QsDepartureInput,
      QsTravellerSection,
      QsButton
    },
    mixins: [currentSelectionMixin],
    data () {
      return {
        valid: true,
        isError: false,
        isDisabled: false,
        selected: [],
        destination: '',
        ibe: '',
        contains_mandatories: '',
        development: false
      }
    },
    mounted () {
      this.selected = this.destinationSelection
      this.destination = this.destinationSelection?.id
      this.ibe = this.currentIbe.refId
    },
    watch: {
      destinationSelection (val) {
        this.selected = val
        this.isError = false
        this.isDisabled = false
      },
      currentIbe (val) {
        this.ibe = val
        this.isError = false
        this.isDisabled = false
      }
    },
    methods: {
      checkSelected () {
        this.contains_mandatories = this.currentIbe.contains_mandatories
        if (this.contains_mandatories) {
          if (this.selected) {
            this.isError = false
            this.isDisabled = false
          } else {
            this.isError = true
            this.isDisabled = true
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "QsContent";
</style>
