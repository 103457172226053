<template>
  <label class="qs-input">
    <span class="qs-input__label qs-text">{{label}}</span>
    <input class="qs-input__input" :class="{'qs-input__slim': slim}" :readOnly="readOnly" :value="value">
  </label>
</template>

<script>

  export default {
    name: 'QsInput',
    props: {
      value: {
        type: String
      },
      label: {
        type: String
      },
      readOnly: {
        type: Boolean,
        default: true
      },
      slim: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "QsInput";
</style>
