const SET_IBE_DATA = (state, data) => {
  state.ibes = data
}
const SET_CURRENT_IBE = (state, data) => {
  state.currentIbe = data
}
const SET_API_ID = (state, data) => {
  state.ibes[state.currentIbe].apiId = data
}

export default {
  SET_IBE_DATA,
  SET_CURRENT_IBE,
  SET_API_ID
}
