const setTravellerState = (state, data) => {
  state.commit('SET_TRAVELLER_STATE', data)
}
const setDepartureAirportState = (state, data) => {
  state.commit('SET_DEPARTURE_AIRPORT_STATE', data)
}
const setDepartureUserInput = (state, data) => {
  state.commit('SET_DEPARTURE_USER_INPUT', data)
}
const setTravelTimeState = (state, data) => {
  state.commit('SET_TRAVEL_TIME_STATE', data)
}
const setTravelDurationState = (state, data) => {
  state.commit('SET_TRAVEL_DURATION_STATE', data)
}
const setDestinationState = (state, data) => {
  state.commit('SET_DESTINATION_STATE', data)
}
const setDestinationValidationError = (state, data) => {
  state.commit('SET_DESTINATION_VALIDATION_ERROR', data)
}
const setDestinationUserInput = (state, data) => {
  state.commit('SET_DESTINATION_USER_INPUT', data)
}
const setShipState = (state, data) => {
  state.commit('SET_SHIP_STATE', data)
}
const setShipSelection = (state, data) => {
  state.commit('SET_SHIP_SELECTION', data)
}
const setCruiseTypeSelection = (state, data) => {
  state.commit('SET_CRUISE_TYPE_STATE', data)
}
const setRentalCarDeparture = (state, data) => {
  state.commit('SET_RENTALCAR_DEPARTURE', data)
}
const setRentalCarReturn = (state, data) => {
  state.commit('SET_RENTALCAR_RETURN', data)
}
const setDestinationSearchQuery = (state, data) => {
  state.commit('SET_DESTINATION_SEARCH_QUERY', data)
}
const setShipSearchQuery = (state, data) => {
  state.commit('SET_SHIP_SEARCH_QUERY', data)
}

export default {
  setTravellerState,
  setDepartureUserInput,
  setDepartureAirportState,
  setTravelTimeState,
  setTravelDurationState,
  setDestinationState,
  setDestinationUserInput,
  setShipState,
  setShipSelection,
  setCruiseTypeSelection,
  setRentalCarDeparture,
  setRentalCarReturn,
  setDestinationSearchQuery,
  setShipSearchQuery,
  setDestinationValidationError
}
