<template>
  <div class="qs-ship-section">
    <multiselect
      group-values="values"
      group-label="category"
      track-by="name"
      label="name"
      ref="shipSelect"
      class="qs-ship-section__multiselect"
      v-model="selected"
      :clear-on-select="false"
      :group-select="true"
      :multiple="false"
      placeholder=""
      :internal-search="false"
      @search-change="searchApi"
      :loading="isLoading"
      @close="showLabel"
      @open="listApi"
      :options="options">
      <template slot="singleLabel">
        <div></div>
      </template>
      <template slot="noOptions">
        <span class="qs-text">{{$t( 'global.more-letters')}}</span>
      </template>
      <template slot="noResult">
        <span class="qs-text qs-ship-section__info" v-if="search.length >= 3">{{$t( 'global.no-elements-found')}}</span>
        <span class="qs-text" v-else>{{$t( 'global.more-letters')}}</span>
      </template>
      <template slot="beforeList">
        <div class="qs-ship-section__detail__head">
          <span class="qs-ship-section__detail__head__delete qs-text" @click="removeAll" v-if="removeAllAllowed">
            {{$t(currentIbe.translation_key + translationPrefix + 'action.remove')}}
          </span>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="qs-departure-airport-section__multiselect__option qs-ship-section__multiselect__option">
          <template v-if="props.option.$isLabel">
            <!--headline of option list-->
            <template v-if="showSingleCategoryLabel || options.filter(array => array.values.length > 0).length > 1">
                <span class="qs-text">
                  {{$t(currentIbe.translation_key + translationPrefix + props.option.$groupLabel)}}
                </span>
              <div class="qs-line"></div>
            </template>
          </template>
          <template v-else>
            <span class="qs-text" v-html="highlightPhrase(props.option.name)"/>
          </template>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>

import currentSelectionMixin from '../../mixins/currentSelectionMixin'
import ibeDataMixin from '../../mixins/ibeDataMixin'
import Multiselect from 'vue-multiselect'
import axios from 'axios'

export default {
  name: 'QsShipSection',
  components: { Multiselect },
  mixins: [currentSelectionMixin, ibeDataMixin],
  props: {
    removeAllAllowed: {
      type: Boolean,
      default: true
    },
    minQueryLength: {
      type: Number,
      default: 3
    },
    loadOnOpen: {
      type: Boolean,
      default: false
    },
    showSingleCategoryLabel: {
      type: Boolean,
      default: true
    },
    translationPrefix: {
      type: String,
      default: '.shipping_company.'
    },
    translation_key: {
      type: String
    }
  },
  data () {
    return {
      selected: null,
      options: [],
      isLoading: false,
      search: ''
    }
  },
  mounted () {
    this.selected = this.shipSelection
    this.showLabel()
  },
  methods: {
    highlightPhrase (text) {
      if (!this.search.length) return text
      return text.replace(new RegExp('(' + this.search + ')', 'ig'), `<strong>$1</strong>`)
    },
    // filtered list
    async searchApi (q = '', force = false, removeAll = false) {
       if (q !== '') {
        this.search = q
        this.setShipSearchQuery(q)
        this.options = []
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + 'locations/' + this.currentIbe.apiId,
          { params: { q, provider: this.currentIbe.provider } }
        )
       const search_type_choice = this.currentIbe.search_type_choice
       if (search_type_choice === 'shipping-company-list') {
         this.options = data.filter(entry => entry.category === 'shipping-company')
       } else {
         this.options = data.filter(entry => entry.category === 'ship')
       }
        this.isLoading = false
        return
      }
      this.isLoading = true
    },
    // just show full list
    async listApi () {
      if (this.minQueryLength === 0) {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + 'locations/' + this.currentIbe.apiId,
          { params: { q: '', provider: this.currentIbe.provider } }
        )
        this.options = []
        const search_type_choice = this.currentIbe.search_type_choice
        if (search_type_choice === 'shipping-company-list') {
          this.options = data.filter(entry => entry.category === 'shipping-company')
        } else {
          this.options = data.filter(entry => entry.category === 'ship')
        }
      }
    },
    showLabel () {
      this.$nextTick(() => {
        if (this.selected) {
          this.$refs.shipSelect.$refs.search.value = this.selected.name
        } else if (this.destinationSearchQuery) {
          this.$refs.shipSelect.$refs.search.value = this.destinationSearchQuery
        } else {
          this.$refs.shipSelect.$refs.search.value = this.$t(this.currentIbe.translation_key + this.translationPrefix + 'label')
        }
      })
    },
    removeAll () {
      this.$refs.shipSelect.$refs.search.blur()
      this.search = ''
      this.setShipSearchQuery('')
      this.options = []
      this.selected = null
    }
  },
  watch: {
    currentIbe () {
      this.removeAll()
      this.showLabel()
    },
    cruiseTypeSelection () {
      this.removeAll()
      this.showLabel()
    },
    shipSelection (val) {
      if (this.selected && val && this.selected.id === val.id) {
        return
      }
      this.selected = val
    },
    selected () {
      this.setShipState(this.options.reduce((result, category) =>
        result || (category.values.find(value => value === this.selected) ? {
          ...this.selected,
          category: category.category
        } : false), false))
    }
  }
}
</script>

<style lang="scss">
@import "QsShipSection";
</style>
