<template>
  <div class="qs-traveller-section" v-click-outside="hideDetail">
    <div v-if="adultsAndChildren">
      <div class="qs-traveller-section__minimized" @click="toggleDetailVisibility" :class="{'qs-error':$v.$anyError}">
        <QsInput :label="$t(currentIbe.translation_key + '.travellers.label')"
          :value="summaryText"
        ></QsInput>
      </div>
      <div v-show="detailVisible" class="qs-traveller-section__detail">
        <div class="qs-traveller-section__detail__head">
          <div class="qs-traveller-section__detail__summary">
            <span class="qs-text qs-text__title--base">
              {{$t(currentIbe.translation_key + '.travellers.label')}}
            </span>
            <span class="qs-text qs-text__bold">
              {{summaryText}}
            </span>
          </div>
        </div>
        <hr class="qs-traveller-section__detail__hr">
        <div class="qs-traveller-section__detail__body">
          <QsCounter v-model="adultCount"
            :label="$t(currentIbe.translation_key + '.travellers.adults.label')"
            :description="$t(currentIbe.translation_key + '.travellers.adults.classification')"
            :min="1"
            :invalid="$v.adultCount.$error"
            :max="currentIbe.travellers.max_adults">
          </QsCounter>
          <QsCounter v-model="childrenCount"
            v-if="currentIbe.travellers.max_children"
            :label="$t(currentIbe.translation_key + '.travellers.children.label')"
            :description="$t(currentIbe.translation_key + '.travellers.children.classification')"
            :min="0"
            :invalid="$v.childrenCount.$error"
            :max="currentIbe.travellers.max_children">
          </QsCounter>
          <div class="qs-traveller-section__detail__body__children" v-if="childrenCount > 0">
            <span class="qs-text">{{$t(currentIbe.translation_key + '.travellers.children.age.label')}}</span>
            <QsSelect
              v-for="(child, key) in this.children"
              v-bind:key="key"
              @input="setChildAge($event, child)"
              :value="child.age"
              :options="childrenAges"
              :invalid="$v.children.$each[key].$error"
              :place-holder="$t(currentIbe.translation_key + '.travellers.children.age.placeholder')"
            >
            </QsSelect>
          </div>
          <div v-if="$v.$anyError && !$v.children.infantCount" class="qs-traveller-section__detail__body__error">
            <span class="qs-text qs-text__bold">Anzahl an Kleinkindern (0-24 Monate) darf die der mitreisenden Erwachsenen nicht übersteigen</span>
          </div>
          <div v-else-if="$v.$anyError" class="qs-traveller-section__detail__body__error">
            <span class="qs-text qs-text__bold">Bitte füllen Sie alle Felder aus</span>
          </div>
          <QsButton
            @action="submit"
            class="qs-traveller-section__detail__body__button"
            :label="$t(currentIbe.translation_key + '.travellers.action.apply')"
            full-width>
          </QsButton>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="qs-traveller-section__minimized qs-traveller-section__dropdown">
        <label class="qs-input">
          <QsSelect
            class="qs-input__input qs-input__list"
            :place-holder="$t(currentIbe.translation_key + '.travellers.label')"
            :label="$t(currentIbe.translation_key + '.travellers.label')"
            :value="travellerCount"
            :options="dropdownOptions"
            @input="updateSelection($event.target.value)"
          ></QsSelect>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

  import QsCounter from '../QsCounter/QsCounter'
  import QsSelect from '../QsSelect/QsSelect'
  import QsButton from '../QsButton/QsButton'
  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import QsInput from '../QsInput/QsInput'
  import ibeDataMixin from '../../mixins/ibeDataMixin'
  import { maxValue, minLength, required } from 'vuelidate/lib/validators'
  import between from 'vuelidate/src/validators/between'
  import ClickOutside from 'vue-click-outside'

  export default {
    name: 'QsTravellerSection',
    components: { QsInput, QsButton, QsSelect, QsCounter },
    mixins: [currentSelectionMixin, ibeDataMixin],
    props: {
      maxTravellers: {
        type: Number
      }
    },
    data () {
      return {
        adultsAndChildren: true,
        dropdownOptions: [],
        adultCount: null,
        childrenCount: '',
        children: [],
        travellerCount: null,
        detailVisible: false
      }
    },
    created () {
      const ibe = this.currentIbe.refId
      const provider = this.currentIbe.provider
      if (ibe) {
        this.adultCount = this.currentIbe.travellers.travellers_default.adults
        this.travellerCount = this.currentIbe.travellers.travellers_default.adults
        this.childrenCount = this.childrenCount.length !== 0 ? this.childrenCount : null
        this.travellerSelection.adults = this.currentIbe.travellers.travellers_default.adults
      } else if (ibe && provider === 'profewo') {
        this.travellerCount = this.currentIbe.travellers.travellers_default.adults
        this.adultCount = this.currentIbe.travellers.travellers_default.adults
      }
    },
    beforeMount () {
      const provider = this.currentIbe.provider
      if (provider === 'profewo') {
        this.adultsAndChildren = false
        for (var i = 1; i < this.maxTravellers; i++) {
          this.dropdownOptions[i - 1] = { label: this.$tc(this.currentIbe.translation_key + '.travellers.adults.count', i, { n: i }), value: i }
        }
        this.dropdownOptions[this.maxTravellers - 1] = { label: this.$tc(this.currentIbe.translation_key + '.travellers.adults.count', this.maxTravellers, {
            n: (this.maxTravellers - 1) + '+'
          }),
        value: this.maxTravellers }
      }
    },
    computed: {
      summaryText () {
        let summary = this.$tc(this.currentIbe.translation_key + '.travellers.adults.count', this.adultCount, { n: this.adultCount })
        if (this.currentIbe.travellers.max_children) {
          summary += ', ' +
            this.$tc(
              this.currentIbe.translation_key + '.travellers.children.count',
              this.childrenCount,
              { n: this.childrenCount }
            )
        }
        return summary
      },
      childrenAges () {
        return [...Array(this.maxAge + 1 - this.minAge).keys()].map(i => i + this.minAge).map(age => {
          return {
            value: age,
            label: this.$tc(this.currentIbe.translation_key + '.travellers.children.age.option', age, { n: age })
          }
        })
      },
      setTravellerCount () {
        let travellerCount = [1, 2, 3, 4, 5]
        return travellerCount
      },
      minAge () {
        return this.currentIbe.travellers.child_age_range.split('-').map(i => parseInt(i))[0]
      },
      maxAge () {
        return this.currentIbe.travellers.child_age_range.split('-').map(i => parseInt(i))[1]
      }
    },
    watch: {
      currentIbe: {
        immediate: true,
        handler () {
          this.adultCount = this.travellerSelection.adults !== 0 ? this.travellerSelection.adults : this.currentIbe.travellers.travellers_default.adults

          if (this.travellerSelection.children.length > this.currentIbe.travellers.max_children) {
            this.children = []
          } else {
            this.children = this.travellerSelection.children
          }

          this.childrenCount = this.children.length
          this.setTravellerSelection({ adults: this.adultCount, children: this.children })
          this.$v.$touch()
        }
      },
      '$v.$invalid' (invalid) {
          this.$emit('valid', !invalid)
      },
      childrenCount () {
        this.$v.$reset()
        if (this.children.length > this.childrenCount) {
          this.children.pop()
        } else if (this.children.length < this.childrenCount) {
          this.children.push({ age: null })
        }
      }
    },
    methods: {
      updateSelection (traveller) {
        this.travellerCount = traveller
        this.setTravellerSelection({ adults: traveller, children: [] })
      },
      toggleDetailVisibility () {
        this.$v.$touch()
        this.detailVisible = !this.detailVisible
      },
      hideDetail () {
        if (this.detailVisible) {
          this.submit()
          this.detailVisible = false
        }
      },
      submit () {
        this.$v.$touch()
        if (this.$v.$error) {
          return
        }
        this.toggleDetailVisibility()
        this.setTravellerSelection({ adults: this.adultCount, children: this.children })
      },
      setChildAge (event, child) {
        child.age = parseInt(event.target.value)
      }
    },
    validations () {
      const infantCount = (value) => {
        if (!value || !value.length) {
          return true
        }
        return value.filter(({ age = null }) => age !== null && age < 2).length <= this.adultCount
      }
      return {
        adultCount: {
          required,
          between: between(1, this.currentIbe.travellers.max_adults)
        },
        childrenCount: {
          maxValue: maxValue(this.currentIbe.travellers.max_children)
        },
        children: {
          minLength: minLength(this.childrenCount),
          infantCount,
          $each: {
            age: {
              required,
              between: between(this.minAge, this.maxAge)
            }
          }
        }
      }
    },
    directives: {
      ClickOutside
    }
  }
</script>

<style scoped lang="scss">
  @import "QsTravellerSection";
</style>
