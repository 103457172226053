<template>
  <div class="qs-destination-section" :class="[{'qs-destination-section--half':half},{'qs-destination-section--full':full}, {'qs-error':destinationSelectionValidationError}]">
    <multiselect
      group-values="values"
      group-label="category"
      track-by="id"
      label="name"
      ref="destinationSelect"
      class="qs-destination-section__multiselect"
      v-model="selected"
      :clear-on-select="false"
      :group-select="true"
      placeholder=""
      :multiple="false"
      :internal-search="false"
      @search-change="searchApi"
      :loading="isLoading"
      @close="showLabel"
      @input="multiselectClosed"
      @open="listApi"
      :options="options">
      <template slot="singleLabel">
        <div></div>
      </template>
      <template slot="noOptions">
        <span class="qs-text qs-destination-section__info">{{$t( 'global.more-letters')}}</span>
      </template>
      <template slot="noResult">
        <span class="qs-text qs-destination-section__info" v-if="search.length >= 3">{{$t( 'global.no-elements-found')}}</span>
        <span class="qs-text qs-destination-section__info" v-else>{{$t( 'global.more-letters')}}</span>
      </template>
      <template slot="beforeList">
        <div class="qs-destination-section__detail__head" v-if="removeAllAllowed">
          <span class="qs-destination-section__detail__head__delete qs-text" @click="removeAll">
            {{$t(currentIbe.translation_key + translationPrefix + 'action.remove')}}
          </span>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="qs-departure-airport-section__multiselect__option qs-destination-section__multiselect__option">
          <template v-if="props.option.$isLabel">
            <template v-if="showSingleCategoryLabel || options.filter(array => array.values.length > 0).length > 1">
                <span class="qs-text">
                  {{$t(currentIbe.translation_key + translationPrefix + props.option.$groupLabel)}}
                </span>
              <div class="qs-line"></div>
            </template>
          </template>
          <template v-else>
            <span class="qs-text" v-html="highlightPhrase(props.option.name)"/>
          </template>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>

  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import ibeDataMixin from '../../mixins/ibeDataMixin'
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'

  export default {
    name: 'QsDestinationSection',
    components: { Multiselect },
    mixins: [currentSelectionMixin, ibeDataMixin],
    props: {
      removeAllAllowed: {
        type: Boolean,
        default: true
      },
      minQueryLength: {
        type: Number,
        default: 3
      },
      loadOnOpen: {
        type: Boolean,
        default: false
      },
      showSingleCategoryLabel: {
        type: Boolean,
        default: true
      },
      full: {
        type: Boolean,
        default: false
      },
      half: {
        type: Boolean,
        default: false
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      translationPrefix: {
        type: String,
        default: '.destination.'
      },
      limitOptions: {
        type: Boolean,
        default: false
      },
      translation_key: {
        type: String
      }
    },
    data () {
      return {
        selected: null,
        options: [],
        isLoading: false,
        search: '',
        cancelToken: axios.CancelToken,
        token: null
      }
    },
    mounted () {
      this.selected = this.destinationSelection
      this.showLabel()
    },
    updated () {
      if (this.currentIbe.translation_key === '.ship.') {
        this.showLabel()
      }
    },
    watch: {
      currentIbe () {
        this.removeAll()
        this.showLabel()
      },
      cruiseTypeSelection () {
        this.removeAll()
        this.showLabel()
      },
      destinationSelection (val) {
        if (this.selected && val && this.selected.id === val.id) {
          return
        }
        this.selected = val
      },
      selected () {
        this.setDestinationState(this.options.reduce((result, category) =>
          result || (category.values.find(value => value === this.selected) ? {
            ...this.selected,
            category: category.category
          } : false), false))
      }
    },
    methods: {
      highlightPhrase (text) {
        if (!this.search.length) return text
        return text.replace(new RegExp('(' + this.search + ')', 'ig'), `<strong>$1</strong>`)
      },
      multiselectClosed (value) {
        if (this.currentIbe.provider === 'sunnycars') {
          this.setDestinationValidationError(!value?.id)
        }
      },
      cancelRequest () {
        if (this.token) {
          this.token.cancel('abort')
        }
      },
      // filtered list
      async searchApi (q = '', force = false) {
        if (q !== '') {
          this.search = q
          this.setDestinationSearchQuery(q)
          this.options = []
          try {
            this.cancelRequest()
            this.token = this.cancelToken.source()
            const { data } = await axios.get(
              process.env.VUE_APP_API_URL + 'locations/' + this.currentIbe.apiId,
              { params: { q, provider: this.currentIbe.provider }, cancelToken: this.token.token }
            )
            if (this.currentIbe.translation_key === 'cruise') {
              this.options = data.filter(entry => entry.category !== 'cruise')
            } else if (this.currentIbe.translation_key !== 'cruise') {
              this.options = data.filter(entry => entry.category !== 'ship')
            }
          } catch (error) {
            if (!axios.isCancel(error)) throw error
          }
          this.isLoading = false
          return
        }
        this.isLoading = true

        if (this.currentIbe.provider === 'profewo') {
          this.options.forEach(cat => cat.values.splice(5))
        }
      },
      // just show full list
      async listApi () {
        if (this.minQueryLength === 0) {
          const { data } = await axios.get(
            process.env.VUE_APP_API_URL + 'locations/' + this.currentIbe.apiId,
            { params: { q: '', provider: this.currentIbe.provider } }
          )
          this.options = []
          if (this.currentIbe.translation_key === 'cruise') {
            this.options = data.filter(entry => entry.category === 'cruise')
          } else if (this.currentIbe.translation_key !== 'cruise') {
            this.options = data.filter(entry => entry.category === 'ship')
          }
        }
      },
      showLabel () {
        this.$nextTick(() => {
          if (this.selected) {
            this.$refs.destinationSelect.$refs.search.value = this.selected.name
          } else if (this.destinationSearchQuery) {
            this.$refs.destinationSelect.$refs.search.value = this.destinationSearchQuery
          } else {
            this.$refs.destinationSelect.$refs.search.value = this.$t(this.currentIbe.translation_key + this.translationPrefix + 'label')
          }
        })
      },
      removeAll () {
        this.$refs.destinationSelect.$refs.search.blur()
        this.search = ''
        this.setDestinationSearchQuery('')
        this.options = []
        this.selected = null
      }
    }
  }
</script>

<style lang="scss">
  @import "QsDestinationSection";
</style>
