<template>
  <div class="qs-date-time-selection-wrapper">
    <QsDateTimeSelection v-model="departureDateTime"
                         :translation-prefix="currentIbe.translation_key + '.dates.departure_'"
                         :times="currentIbe.date_options.duration.time_options.departure_times"
                         :min-date="minDeparture"
                         :max-date="maxDeparture"
    />
    <QsDateTimeSelection v-model="returnDateTime"
                         :translation-prefix="currentIbe.translation_key + '.dates.return_'"
                         :times="currentIbe.date_options.duration.time_options.return_times"
                         :min-date="minReturn"
                         :max-date="maxReturn"
    />
  </div>
</template>

<script>
  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import ibeDataMixin from '../../mixins/ibeDataMixin'
  import QsDateTimeSelection from '../QsDateTimeSelection/QsDateTimeSelection'
  import day from 'dayjs'

  export default {
    name: 'QsDateTimeSpanSection',
    components: { QsDateTimeSelection },
    mixins: [currentSelectionMixin, ibeDataMixin],
    data () {
      return {
        departureDateTime: null,
        returnDateTime: null
      }
    },
    mounted () {
      this.departureDateTime = this.rentalCarDeparture
      if (!this.departureDateTime) {
        const timeParts = this.currentIbe.date_options.duration.default_departure_time.split(':')
        this.departureDateTime = day(this.dateRules.default_departure_date)
          .hour(timeParts[0])
          .minute(timeParts[1])
          .toDate()
      }
      this.returnDateTime = this.rentalCarReturn
      if (!this.returnDateTime) {
        const timeParts = this.currentIbe.date_options.duration.default_return_time.split(':')
        this.returnDateTime = day(this.dateRules.default_return_date)
          .hour(timeParts[0])
          .minute(timeParts[1])
          .toDate()
      }
    },
    computed: {
      dateRules () {
        return this.currentIbe.date_options.departure_return
      },
      minDeparture () {
        return this.dateRules.min_selectable_departure_date
      },
      maxDeparture () {
        return this.dateRules.max_selectable_departure_date
        },
      minReturn () {
        if (!this.departureDateTime) {
          return this.departureDateTime
        }
        return new Date(this.departureDateTime.getTime() + (this.dateRules.min_selectable_time_span - new Date()))
      },
      maxReturn () {
        return this.dateRules.max_selectable_return_date
      }
    },
    watch: {
      departureDateTime () {
        if (day(this.returnDateTime).isBefore(this.departureDateTime)) {
          this.returnDateTime = day(this.departureDateTime).add(
            this.currentIbe.date_options.duration.default_duration, 'days'
          ).toDate()
        }
        this.setRentalCarDeparture(this.departureDateTime)
      },
      returnDateTime () {
        this.setRentalCarReturn(this.returnDateTime)
      }
    }
  }
</script>

<style lang="scss">
  @import "QsDateTimeSpanSection";
</style>
