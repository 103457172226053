<template>
  <div class="qs-date-time-selection" v-click-outside="()=>{calendarDetailVisible=false}">
    <div class="qs-date-time-selection__minimized">
      <div>
        <div @click="calendarDetailVisible = true" class="qs-date-time-selection__minimized__date">
          <QsInput :label="$t(translationPrefix +'date.label')"
                   :value="calendarSummaryText"
          />
        </div>
        <QsSelect :options="times.map(time => ({label: time, value: time}))"
                  :place-holder="$t(translationPrefix +'time.label')"
                  :value="time"
                  :label="$t(translationPrefix +'time.label')"
                  @input="updateSelection(date, $event.target.value)"
                  small
        ></QsSelect>
      </div>
    </div>
    <div v-show="calendarDetailVisible"
         class="qs-date-time-selection__detail qs-date-time-selection__detail--spacing ">
      <div class="qs-date-time-selection__detail__head">
        <div class="qs-date-time-selection__detail__summary">
          <div>
            <p class="qs-date-time-selection__detail__summary__label">
              {{$t(translationPrefix +'date.label')}}
            </p>
            <p class="qs-date-time-selection__detail__summary__value">
              {{calendarSummaryText}}
            </p>
          </div>
        </div>
      </div>
      <hr class="qs-date-time-selection__detail__hr">
      <div class="qs-date-time-selection__detail__body">
        <v-date-picker
          is-expanded
          is-inline
          ref="calendar"
          :value="date"
          :min-date="minDate"
          :max-date="maxDate"
          :theme="calendarTheme"
          @input="updateSelection($event, time)"
        />
        <QsButton
          @action="calendarDetailVisible = false"
          class="qs-date-time-selection__detail__body__button"
          :label="$t(currentIbe.translation_key + '.dates.action.CTA')"
          full-width>
        </QsButton>
      </div>
    </div>
  </div>
</template>

<script>
  import QsButton from '../QsButton/QsButton'
  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import QsInput from '../QsInput/QsInput'
  import ibeDataMixin from '../../mixins/ibeDataMixin'
  import ClickOutside from 'vue-click-outside'
  import theme from '../../datePickerTheme.json'
  import QsSelect from '../QsSelect/QsSelect'
  import day from 'dayjs'

  export default {
    name: 'QsDateTimeSelection',
    components: { QsSelect, QsInput, QsButton },
    mixins: [currentSelectionMixin, ibeDataMixin],
    props: {
      translationPrefix: {
        type: String,
        default: ''
      },
      value: {
        type: Date,
        default: null
      },
      minDate: {
        type: Date,
        default: null
      },
      maxDate: {
        type: Date,
        default: null
      },
      times: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        calendarDetailVisible: false,
        calendarTheme: theme,
        time: '',
        date: null
      }
    },
    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.date = val
          this.time = day(val).format('HH:mm')

          // only works with newer version of v-calendar
          // const calendar = this.$refs.calendar
          // if (calendar) {
          //   calendar.$refs.calendar.move(val)
          // }
        }
      }
    },
    computed: {
      calendarSummaryText () {
        return this.value ? this.value.toLocaleDateString() : 'Dummy'
      }
    },
    methods: {
      updateSelection (date, time) {
        this.date = date
        this.time = time
        const timeParts = this.time.split(':')
        this.$emit(
          'input',
          day(this.date)
            .hour(timeParts[0])
            .minute(timeParts[1])
            .toDate()
        )
      }
    },
    directives: {
      ClickOutside
    }
  }
</script>

<style lang="scss">
  @import "QsDateTimeSelection";
</style>
