<template>
  <div class="qs-select" :class="{'qs-error':invalid}">
    <label>
      <span v-if="label" class="qs-select__label qs-text">{{label}}</span>
      <select class="qs-select__input"
              :class="[{'qs-select__input__disabled': value=== null}, {'qs-select__input--small': small}]"
              @input="$emit('input' , $event)"
              :value="value">
        <option disabled :value="null">{{placeHolder}}</option>
        <option v-for="(option, index) in options" v-bind:value="option.value" v-bind:key="index">
          {{ option.label }}
        </option>
      </select>
    </label>
  </div>
</template>

<script>

  export default {
    name: 'QsSelect',
    props: {
      options: {
        type: Array
      },
      placeHolder: {
        type: String
      },
      label: {
        type: String
      },
      invalid: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      value: {}
    }
  }
</script>

<style scoped lang="scss">
  @import "QsSelect";
</style>
