const state = state => state
const travellerSelection = state => state.travellers
const departureAirportSelection = state => state.departureAirports
const departureUserInput = state => state.departureUserInput
const travelTimeSelection = state => state.travelTime
const travelDurationSelection = state => state.travelDuration
const destinationSelection = state => state.destination
const destinationUserInput = state => state.destinationUserInput
const shipSelection = state => state.ship
const cruiseTypeSelection = state => state.cruiseType
const rentalCarDeparture = state => state.rentalCar.departureDateTime
const rentalCarReturn = state => state.rentalCar.returnDateTime
const destinationSearchQuery = state => state.destinationSearchQuery
const shipSearchQuery = state => state.shipSearchQuery
const destinationSelectionValidationError = state => {
  return state.destinationValidationError
}

export default {
  state,
  travellerSelection,
  departureAirportSelection,
  departureUserInput,
  travelTimeSelection,
  travelDurationSelection,
  destinationSelection,
  destinationUserInput,
  shipSelection,
  cruiseTypeSelection,
  rentalCarDeparture,
  rentalCarReturn,
  destinationSearchQuery,
  shipSearchQuery,
  destinationSelectionValidationError
}
