<template>
  <div class="qs-cruise-type-section">
    <label :class="{'is-selected':selected ==='sea'}">
        {{$t(currentIbe.translation_key + '.cruising_area.sea.label')}}
      <input type="radio" value="sea" v-model="selected">
    </label>
    <label :class="{'is-selected':selected ==='river'}">
      {{$t(currentIbe.translation_key + '.cruising_area.river.label')}}
      <input type="radio" value="river" v-model="selected">
    </label>
  </div>
</template>

<script>

  import currentSelectionMixin from '../../mixins/currentSelectionMixin'
  import ibeDataMixin from '../../mixins/ibeDataMixin'

  export default {
    name: 'QsCruiseTypeSection',
    mixins: [currentSelectionMixin, ibeDataMixin],
    data () {
      return {
        selected: null
      }
    },
    mounted () {
      this.selected = this.cruiseTypeSelection || this.currentIbe.cruise_type_choice
    },
    watch: {
      selected (val) {
        this.setCruiseTypeSelection(val)
        this.$store.dispatch('ibeData/setApiId', val === 'sea' ? 256 : 128)
        this.setDestinationState(null)
      }
    }
  }
</script>

<style lang="scss">
  @import "QsCruiseTypeSection";
</style>
