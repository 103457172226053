import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  currentIbe: null,
  travellers: {
    adults: 0,
    children: []
  },
  departureAirports: [],
  departureUserInput: '',
  travelTime: {
    start: null,
    end: null
  },
  travelDuration: {
    value: null,
    unit: null
  },
  destination: null,
  destinationValidationError: false,
  destinationUserInput: '',
  destinationSearchQuery: null,
  shipSearchQuery: null,
  shipSelection: null,
  ship: null,
  cruiseType: null,
  rentalCar: {
    departureDateTime: null,
    returnDateTime: null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
