import mockData from '../../data'
import day from 'dayjs'

const lodashMapValues = require('lodash.mapvalues')
const merge = require('lodash.merge')

const setCurrentIbe = (state, data) => {
  state.commit('SET_CURRENT_IBE', data)
}

const setApiId = (state, data) => {
  state.commit('SET_API_ID', data)
}

const setIbeData = async (state, config) => {
  let ibesOrder = Object.keys(config)
  if (ibesOrder.length === 0) {
    ibesOrder = Object.keys(mockData)
  }

  let rawData = merge(mockData, config)
  let data = ibesOrder.map(key => rawData[key]).filter(i => i)

  data.forEach(ibe => {
    if (!ibe.date_options?.departure_return) {
      return
    }
    ibe.date_options.departure_return = lodashMapValues(ibe.date_options.departure_return, v => {
      const matches = v.match(/^([-+])(\d*)\s*(\w*?)$/)
      if (matches) {
        return day()[matches[1] === '+' ? 'add' : 'substract'](matches[2], matches[3]).toDate()
      }
      return v
    })
    ibe.date_options.departure_return = lodashMapValues(ibe.date_options.departure_return, v => {
      if (typeof v !== 'string') {
        return v
      }
      const matches = v.match(/^(\w*)\s*([-+])\s*(\w*)$/)
      if (matches) {
        return new Date(ibe.date_options.departure_return[matches[1]].getTime() + (ibe.date_options.departure_return[matches[3]].getTime() * (matches[2] === '+' ? 1 : -1)) / 1000)
      }
      return v
    })
    ibe.date_options.departure_return = lodashMapValues(ibe.date_options.departure_return, v => {
      if (typeof v !== 'string') {
        return v
      }
      if (v === 'today') {
        return new Date()
      }
    })
  })

  state.commit('SET_IBE_DATA', data)
}

export default {
  setIbeData,
  setCurrentIbe,
  setApiId
}
