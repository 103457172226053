<template>
  <button
    class="qs-button qs-text__upper"
    @click="emitClick"
    :class="{'qs-button__fullwidth' : fullWidth, disabled}"
  >
    {{label}}
  </button>
</template>

<script>
  export default {
    name: 'QsButton',
    props: {
      label: {
        type: String
      },
      fullWidth: {
        type: Boolean
      },
      disabled: {
        type: Boolean
      }
    },
    methods: {
      emitClick () {
        this.$emit('action')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "QsButton";
</style>
