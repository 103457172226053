const SET_TRAVELLER_STATE = (state, data) => {
  state.travellers = data
}
const SET_DEPARTURE_AIRPORT_STATE = (state, data) => {
  state.departureAirports = data
}
const SET_DEPARTURE_USER_INPUT = (state, data) => {
  state.departureUserInput = data
}
const SET_TRAVEL_TIME_STATE = (state, data) => {
  state.travelTime = data
}
const SET_TRAVEL_DURATION_STATE = (state, data) => {
  state.travelDuration = data
}
const SET_DESTINATION_STATE = (state, data) => {
  state.destination = data
}
const SET_DESTINATION_VALIDATION_ERROR = (state, data) => {
  state.destinationValidationError = data
}
const SET_DESTINATION_USER_INPUT = (state, data) => {
  state.destinationUserInput = data
}
const SET_SHIP_STATE = (state, data) => {
  state.ship = data
}
const SET_SHIP_SELECTION = (state, data) => {
  state.shipSelection = data
}
const SET_CRUISE_TYPE_STATE = (state, data) => {
  state.cruiseType = data
}
const SET_RENTALCAR_DEPARTURE = (state, data) => {
  state.rentalCar.departureDateTime = data
}
const SET_RENTALCAR_RETURN = (state, data) => {
  state.rentalCar.returnDateTime = data
}
const SET_DESTINATION_SEARCH_QUERY = (state, data) => {
  state.destinationSearchQuery = data
}
const SET_SHIP_SEARCH_QUERY = (state, data) => {
  state.shipSearchQuery = data
}

export default {
  SET_TRAVELLER_STATE,
  SET_DEPARTURE_AIRPORT_STATE,
  SET_DEPARTURE_USER_INPUT,
  SET_TRAVEL_TIME_STATE,
  SET_TRAVEL_DURATION_STATE,
  SET_DESTINATION_STATE,
  SET_DESTINATION_USER_INPUT,
  SET_SHIP_STATE,
  SET_SHIP_SELECTION,
  SET_CRUISE_TYPE_STATE,
  SET_RENTALCAR_DEPARTURE,
  SET_RENTALCAR_RETURN,
  SET_DESTINATION_SEARCH_QUERY,
  SET_SHIP_SEARCH_QUERY,
  SET_DESTINATION_VALIDATION_ERROR
}
